define("discourse/plugins/discourse-reactions/discourse/templates/user-activity-reactions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LoadMore @selector=".user-stream-item" @action={{action "loadMore"}}>
    <div class="user-stream">
      {{#each @model as |reaction|}}
        <DiscourseReactionsReactionPost @reaction={{reaction}} />
      {{else}}
        <div class="alert alert-info">{{i18n "notifications.empty"}}</div>
      {{/each}}
    </div>
  
    <ConditionalLoadingSpinner @condition={{this.loading}} />
  </LoadMore>
  */
  {
    "id": "ogsNsmF9",
    "block": "[[[8,[39,0],null,[[\"@selector\",\"@action\"],[\".user-stream-item\",[28,[37,1],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"user-stream\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"      \"],[8,[39,4],null,[[\"@reaction\"],[[30,2]]],null],[1,\"\\n\"]],[2]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[35,5],[\"notifications.empty\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[8,[39,6],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],null],[1,\"\\n\"]],[]]]]]],[\"@model\",\"reaction\"],false,[\"load-more\",\"action\",\"each\",\"-track-array\",\"discourse-reactions-reaction-post\",\"i18n\",\"conditional-loading-spinner\"]]",
    "moduleName": "discourse/plugins/discourse-reactions/discourse/templates/user-activity-reactions.hbs",
    "isStrictMode": false
  });
});